.category__icon svg {
      height: 32px;
      width: 32px;
}

.product-container {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      padding: 10px;
}

.draggable-item {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;
      background-color: #fff;
      cursor: grab;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      display: flex;
      gap: 20px;
}

.draggable-item img {
      width: 80px;
}

.draggable-row {
      cursor: all-scroll;
}

.draggable-row:active {
      cursor: all-scroll;
}

del {
      color: #666;
}
